<template>
  <div id="kpi-report">
    <CRow>
      <CCol col="8">
        <CRow>
          <CCol>
            <h2>KPI Reports</h2>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <div class="subheader">
              The following data shows the KPI numbers that we're tracking.
              You can use the dropdown menu to select KPI reports for previous
              months.
            </div>
          </CCol>
        </CRow>
      </CCol>
      <CCol col="4" class="month-select">
        <CSelect
          label="Select Month"
          :value="selectedMonth"
          :options="monthOptions"
          @update:value="handleUpdate"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <KPIReport :query="query" @exportData="handleExport" />
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <RevenueReport :query="query" @exportData="handleExport" />
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <CompanyReport :query="query" @exportData="handleExport" />
      </CCol>
    </CRow>
    <DataExportModal
      :show="exportData.showModal"
      :data="exportData.data"
      :file-name="exportData.fileName"
      @close="exportData.showModal = false"
    />
  </div>
</template>

<script>
import { format, parse } from 'date-fns'

import { DataExportModal } from '@/components/modals'
import { ReportingAPI } from '@/services/api/resources'

import CompanyReport from './_components/CompanyReport'
import KPIReport from './_components/KPIReport'
import RevenueReport from './_components/RevenueReport'

const DATE_FMT = 'MMM yyyy'

export default {
  name: 'KPIReportBase',
  components: {
    CompanyReport,
    KPIReport,
    RevenueReport,
    DataExportModal
  },
  data () {
    const today = new Date()
    const month = today.getMonth() + 1
    const year = today.getFullYear()

    return {
      month: null,
      query: { month, year },
      months: [ this.formatMonthYear(month, year) ],
      exportData: {
        showModal: false,
        data: [],
        fileName: ''
      }
    }
  },
  computed: {
    selectedMonth () {
      // CSelect only works with primitives
      return this.formatMonthYear(this.query.month, this.query.year)
    },
    monthOptions () {
      // Prevent duplicates from showing (this can happen
      // on the last day of the month)
      return Array.from(new Set(this.months))
    }
  },
  created () {
    this.fetchHistoricalKpiData()
  },
  methods: {
    /**
     *  Exports data from child components
     *  Adds the month and year of the exported data to the fileName
     *
     * @param {Object} config: contains keys `data` and `fileName`
     */
    handleExport (config) {
      const reportDate = this.formatMonthYear(this.query.month, this.query.year).replace(/\s/g, '')
      this.exportData = {
        data: config.data,
        fileName: `${config.fileName}-${reportDate}`,
        showModal: true
      }
    },
    /**
     * Fetch the list of available kpi data for previous months
     * so that it can be displayed in the month select input.
     *
     * Response will be in the format:
     *
     * [
     *   { month: 8, year: 2020 },
     *   { month: 9, year: 2020 }
     * ]
     */
    async fetchHistoricalKpiData () {
      const response = await ReportingAPI.kpi.list()
      if (response) {
        const orderedDates = this.orderDates(response.data)
        for (const result of orderedDates) {
          this.months.push(this.formatMonthYear(result.month, result.year))
        }
      }
    },
    /**
     * Handle the update event from month select input change.
     *
     * @param {String} selectedValue - selected month/year (MMM yyyy)
     */
    handleUpdate (selectedValue) {
      const date = parse(selectedValue, DATE_FMT, new Date())
      this.query = {
        month: date.getMonth() + 1,
        year: date.getFullYear()
      }
    },
    /**
     * Given a month and year, format it as MMM yyyy.
     *
     * Example: 9 2020 -> Sep 2020
     *
     * @param {Number} month - M
     * @param {Number} year - yyyy
     * @returns {String}
     */
    formatMonthYear (month, year) {
      const date = parse(`${year}-${month}`, 'yyyy-M', new Date())
      return format(date, DATE_FMT)
    },
    /**
     * Given an Array of month and year,
     * order dates in reverse chronological order.
     *
     *
     * @param {Array} dates - Ex. [ { month: 1, year: 2020 },...]
     * @returns {Array}
     */
    orderDates (dates) {
      return dates.sort((a, b) => {
        if (a.year !== b.year) return b.year - a.year
        if (a.month !== b.month) return b.month - a.month
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.month-select {
  display: flex;
  justify-content: flex-end;
}
.subheader {
  font-size: 1rem;
  color: $gray-800;
  padding-bottom: 2rem;
}
</style>
