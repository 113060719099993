<template>
  <RCard>
    <CCardHeader>
      <b>Companies Added/Removed</b>
    </CCardHeader>
    <CCardBody>
      <CRow class="ml-0">
        <h5 class="pt-1 pb-2">
          Added
        </h5>
        <div class="ml-auto mr-3">
          <CButton class="ml-2" shape="pill" @click="handleExportAdded()">
            <CIcon name="exporting" />
            Export
          </CButton>
        </div>
      </CRow>
      <CDataTable
        :items="companies.added"
        :fields="columns"
      />
      <CRow class="ml-0">
        <h5 class="pt-1 pb-2">
          Removed
        </h5>
        <div class="ml-auto mr-3">
          <CButton class="ml-2" shape="pill" @click="handleExportRemoved()">
            <CIcon name="exporting" />
            Export
          </CButton>
        </div>
      </CRow>
      <CDataTable
        :items="companies.removed"
        :fields="columns"
      />
    </CCardBody>
  </RCard>
</template>

<script>
import { format, lastDayOfMonth } from 'date-fns'

import { RCard } from '@/components/cards'
import { RooofAPI } from '@/services/api/resources'

const columns = [
  { key: 'human_name', label: 'Name', _style: 'width: 20%' },
  { key: 'start_date', label: 'Start Date', _style: 'width: 12%' },
  { key: 'end_date', label: 'End Date', _style: 'width: 12%' },
  { key: 'status', label: 'Status', _style: 'width: 7%' },
  { key: 'pending', label: 'Pending', _style: 'width: 7%' },
  { key: 'pre-trial', label: 'Pre-Trial', _style: 'width: 7%' },
  { key: 'trial', label: 'Trial', _style: 'width: 7%' },
  { key: 'onboarding', label: 'Onboarding', _style: 'width: 7%' },
  { key: 'active', label: 'Active', _style: 'width: 7%' },
  { key: 'offboarding', label: 'Offboarding', _style: 'width: 7%' },
  { key: 'inactive', label: 'Inactive', _style: 'width: 7%' }
]

export default {
  name: 'CompanyReport',
  components: {
    RCard
  },
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      companies: {
        added: [],
        removed: []
      }
    }
  },
  watch: {
    query: {
      handler () {
        this.fetchCompanyReport()
      },
      immediate: true
    }
  },
  created () {
    this.columns = columns
  },
  methods: {
    /**
     * Fetch the company kpi report.
     */
    async fetchCompanyReport () {
      const promises = [
        RooofAPI.companies.summary({ params: this.getQueryParams('start_date') }),
        RooofAPI.companies.summary({ params: this.getQueryParams('end_date') })
      ]
      const responses = await Promise.all(promises)
      if (responses) {
        this.companies.added = this.formatData(responses[0].data)
        this.companies.removed = this.formatData(responses[1].data)
      }
    },
    /**
     * Return query param filters for companies added/removed.
     *
     * @param {String} date - start_date or end_date
     * @returns {Object}
     */
    getQueryParams (date) {
      const monthStart = new Date(this.query.year, this.query.month - 1)
      const monthEnd = lastDayOfMonth(monthStart)
      const dateFormat = 'yyyy-MM-dd'
      return {
        [`${date}_after`]: format(monthStart, dateFormat),
        [`${date}_before`]: format(monthEnd, dateFormat)
      }
    },
    /**
     * Given a list of company objects, replace
     * null dates with '-'.
     *
     * @param {Array} companies
     * @returns {Array}
     */
    formatData (companies) {
      return companies.map(company => {
        company.start_date = company.start_date || '-'
        company.end_date = company.end_date || '-'
        return company
      })
    },
    /**
     * Exports Added Companies data
     */
    handleExportAdded () {
      this.$emit('exportData', {
        data: this.companies.added,
        fileName: 'KPI-Report-added-companies'
      })
    },
    /**
     * Exports Removed Companies data
     */
    handleExportRemoved () {
      this.$emit('exportData', {
        data: this.companies.removed,
        fileName: 'KPI-Report-removed-companies'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$padding: 5px 10px;

::v-deep table {
  thead {
    th {
      border: none;
      padding: $padding;
    }
  }

  tbody {
    tr:nth-child(odd) {
      background-color: $gray-50;
    }

    td {
      padding: $padding;
      border: none;
    }
  }
}
</style>
