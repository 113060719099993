<template>
  <div class="r-list">
    <ul>
      <li v-for="item in items" :key="item.key">
        <span>{{ item.label }}</span>
        <span>{{ item.value }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'RKeyValueList',
  props: {
    items: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
ul {
  list-style-type: none;
  padding-left: 0;

  li {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
  }

  li:nth-child(odd) {
    background-color: $gray-50;
  }
}
</style>
