<template>
  <RCard>
    <CCardHeader>
      <b>KPI</b>
    </CCardHeader>
    <CCardBody>
      <CRow class="ml-0">
        <h5 class="pt-1 pb-3">
          MRR (USD)
        </h5>
        <div class="ml-auto mr-3">
          <CButton class="ml-2" shape="pill" @click="handleExportMRR()">
            <CIcon name="exporting" />
            Export
          </CButton>
        </div>
      </CRow>
      <RKeyValueList :items="mrrReport" />
      <CRow class="ml-0">
        <h5 class="pt-1 pb-3">
          Properties
        </h5>
        <div class="ml-auto mr-3">
          <CButton class="ml-2" shape="pill" @click="handleExportPropertyReport()">
            <CIcon name="exporting" />
            Export
          </CButton>
        </div>
      </CRow>
      <RKeyValueList :items="propertyReport" />
    </CCardBody>
  </RCard>
</template>

<script>
import { RCard } from '@/components/cards'
import { RKeyValueList } from '@/components/lists'
import { ReportingAPI } from '@/services/api/resources'
import { formatCurrency } from '@/utils'

export default {
  name: 'KPIReport',
  components: {
    RCard,
    RKeyValueList
  },
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      mrrData: null,
      propertyData: null
    }
  },
  computed: {
    revenuePerProperty () {
      if (this.mrrData && this.propertyData) {
        return this.formatCurrency(this.mrrData.mrr_total / this.propertyData.active_properties)
      }
      return '(none)'
    },
    mrrReport () {
      const data = this.mrrData
      if (!data) {
        return []
      }
      return [
        { label: 'Total MRR', value: this.formatCurrency(data.mrr_total) },
        { label: 'Revenue Per Property', value: this.revenuePerProperty }
      ]
    },
    propertyReport () {
      const data = this.propertyData
      if (!data) {
        return []
      }
      const rows = [
        { label: 'Total Companies', value: data.active_companies },
        { label: 'Total Properties', value: data.active_properties },
        { label: 'Companies Gained', value: data.new_companies },
        { label: 'Companies Lost', value: data.lost_companies },
        { label: 'Properties Gained', value: data.new_properties.total },
        { label: ' — From new companies (current month)', value: data.new_properties.mtd },
        { label: ' — From new companies (last month)', value: data.new_properties.one_month },
        { label: ' — From new companies (prev. six months)', value: data.new_properties.six_months },
        { label: ' — From legacy companies', value: data.new_properties.legacy },
        { label: 'Properties Lost', value: data.lost_properties },
        { label: 'Net Properties', value: data.net_properties },
        { label: 'Cancellations unrelated to sale/change of management', value: data.cancellations_unrelated_to_sale }
      ]
      const subscriptionTotals = []
      data.properties_on_products.forEach((value) => {
        subscriptionTotals.push({ label: `Total Properties on ${value.product__name}`, value: value.total })
      })
      data.properties_on_features.forEach((value) => {
        subscriptionTotals.push({ label: `Total Properties on ${value.feature__name}`, value: value.total })
      })
      rows.splice(11, 0, ...subscriptionTotals)

      return rows
    }
  },
  watch: {
    query: {
      handler () {
        this.fetchMRRReport()
        this.fetchPropertyReport()
      },
      immediate: true
    }
  },
  methods: {
    /**
     * Fetch the mrr kpi report.
     */
    async fetchMRRReport () {
      const response = await ReportingAPI.kpi.mrr({ params: this.query })
      if (response) {
        this.mrrData = response.data
      }
    },
    /**
     * Fetch the property kpi report.
     */
    async fetchPropertyReport () {
      const response = await ReportingAPI.kpi.property({ params: this.query })
      if (response) {
        this.propertyData = response.data
      }
    },
    /**
     * Format a number as a dollar value, or (none).
     *
     * @param {Number} amount
     * @returns {String}
     */
    formatCurrency (amount) {
      return formatCurrency(amount, '(none)')
    },
    /**
     * Exports MRR data
     */
    handleExportMRR () {
      this.$emit('exportData', {
        data: this.mrrReport,
        fileName: 'KPI-Report-MRR'
      })
    },
    /**
     * Exports Property Report data
     */
    handleExportPropertyReport () {
      this.$emit('exportData', {
        data: this.propertyReport,
        fileName: 'KPI-Report-PropertyData'
      })
    }
  }
}
</script>
