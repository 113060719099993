<template>
  <RCard>
    <CCardHeader>
      <b>Incoming/Outgoing Revenue</b>
    </CCardHeader>
    <CCardBody>
      <CRow class="ml-0">
        <h5 class="pt-1 pb-2">
          Incoming
        </h5>
        <div class="ml-auto mr-3">
          <CButton class="ml-2" shape="pill" @click="handleExportIncoming()">
            <CIcon name="exporting" />
            Export
          </CButton>
        </div>
      </CRow>
      <RevenueReportTable
        :data="revenue.incoming"
        direction="incoming"
      />
      <CRow class="ml-0">
        <h5 class="pt-1 pb-2">
          Outgoing
        </h5>
        <div class="ml-auto mr-3">
          <CButton class="ml-2" shape="pill" @click="handleExportOutgoing()">
            <CIcon name="exporting" />
            Export
          </CButton>
        </div>
      </CRow>
      <RevenueReportTable
        :data="revenue.outgoing"
        direction="outgoing"
      />
    </CCardBody>
  </RCard>
</template>

<script>
import { RCard } from '@/components/cards'
import { ReportingAPI } from '@/services/api/resources'
import { deepCopy, formatCurrency } from '@/utils'

import RevenueReportTable from './RevenueReportTable'

export default {
  name: 'RevenueReport',
  components: {
    RCard,
    RevenueReportTable
  },
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      revenue: {
        incoming: [],
        outgoing: []
      }
    }
  },
  watch: {
    query: {
      handler () {
        this.fetchRevenueReport()
      },
      immediate: true
    }
  },
  methods: {
    /**
     * Fetch the revenue kpi report.
     */
    async fetchRevenueReport () {
      const response = await ReportingAPI.kpi.revenue({ params: this.query })
      if (response) {
        this.revenue.incoming = this.formatData(response.data.incoming)
        this.revenue.outgoing = this.formatData(response.data.outgoing)
      }
    },
    /**
     * Formats the revenue report data.
     *
     * - Format currency fields or set to (none) if 0
     * - Add internal fields for tracking table row state
     * - Add summary row to the end of the array
     *
     * @param {Array} revenue
     * @returns {Array}
     */
    formatData (revenue) {
      const summaryRow = {
        human_name: 'Total',
        active: 0,
        count: 0,
        mrr: 0
      }
      const data = revenue.map(el => {
        el._toggled = false
        el._properties = []

        summaryRow.active += el.active
        summaryRow.count += el.count
        summaryRow.mrr += el.mrr || 0
        el.mrr = formatCurrency(el.mrr, '(none)')

        return el
      })
      summaryRow.mrr = formatCurrency(summaryRow.mrr, '(none)')
      data.push(summaryRow)

      return data
    },
    /**
     * Exports Incoming Revenue Data
     */
    handleExportIncoming () {
      const data = deepCopy(this.revenue.incoming)
      for (const property of data) {
        delete property._toggled
        delete property._properties
      }
      this.$emit('exportData', {
        data: data,
        fileName: 'KPI-Report-incoming-revenue'
      })
    },
    /**
     * Exports Outgoing Revenue Data
     */
    handleExportOutgoing () {
      const data = deepCopy(this.revenue.outgoing)
      for (const property of data) {
        delete property._toggled
        delete property._properties
      }
      this.$emit('exportData', {
        data: data,
        fileName: 'KPI-Report-outgoing-revenue'
      })
    }
  }
}
</script>
