<template>
  <CDataTable
    :items="data"
    :fields="getTableColumns()"
  >
    <template #show_properties="{ item, index }">
      <td class="text-right">
        <CButton
          v-if="index !== data.length - 1"
          color="primary"
          variant="outline"
          square
          size="sm"
          @click="togglePropertyList(item)"
        >
          {{ item._toggled ? 'Hide Properties' : 'Show Properties' }}
        </CButton>
      </td>
    </template>
    <template #details="{ item }">
      <CCollapse :show="item._toggled">
        <CCardBody>
          <div v-if="loading">
            Loading...
          </div>
          <ul v-for="property in item._properties" :key="property.id">
            <li>{{ property.name }}</li>
          </ul>
        </CCardBody>
      </CCollapse>
    </template>
  </CDataTable>
</template>

<script>
import { RooofAPI } from '@/services/api/resources'

export default {
  name: 'RevenueReportTable',
  props: {
    direction: {
      type: String,
      required: true,
      validator: value => {
        return value === 'incoming' || value === 'outgoing'
      }
    },
    data: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    /**
     * Get the table field config for CDataTable.
     *
     * @returns {Array}
     */
    getTableColumns () {
      return [
        {
          key: 'human_name',
          label: 'Name',
          _style: 'width: 30%'
        },
        {
          key: 'active',
          label: 'Total Active',
          _style: 'width: 20%'
        },
        {
          key: 'count',
          label: `Properties ${this.direction === 'incoming' ? 'Added' : 'Removed'}`,
          _style: 'width: 20%'
        },
        {
          key: 'mrr',
          label: 'MRR',
          _style: 'width: 15%'
        },
        {
          key: 'show_properties',
          label: '',
          _style: 'width: 15%'
        }
      ]
    },
    /**
     * Show/hide the property list.
     *
     * If this is the first time we're opening the property
     * details section, fetch them from the api.
     *
     * @param {Object} row
     * @param {Number} index
     */
    togglePropertyList (row) {
      this.$set(row, '_toggled', !row._toggled)

      if (!row._properties.length) {
        this.fetchProperties(row)
      }
    },
    /**
     * Given a company (row), fetch the list of property
     * objects which contribute towards the kpi totals
     * for that company.
     *
     * These objects will be accessible under the
     * `_properties` key.
     *
     * @param {Object} row
     */
    async fetchProperties (row) {
      this.loading = true
      const promises = []
      for (const id of row.property_ids) {
        promises.push(RooofAPI.properties.retrieve({ id }))
      }
      const responses = await Promise.all(promises)
      if (responses) {
        const properties = responses.map(response => response.data)
        this.$set(row, '_properties', properties)
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
$padding: 5px 10px;

::v-deep table {
  thead {
    th {
      border: none;
      padding: $padding;
    }
  }

  tbody {
    tr {
      background-color: $gray-50;
    }
    tr:nth-child(4n), tr:nth-child(4n-1) {
      background-color: $white;
    }
    td {
      padding: $padding;
      border: none;
    }
  }

  ul {
    margin-bottom: 0.5rem;
  }
}
</style>
