<template>
  <div class="r-card">
    <CCard>
      <slot />
    </CCard>
  </div>
</template>

<script>
/**
 * RCard
 *
 * Wrapper for CCard with custom styles.
 */
export default {
  name: 'RCard'
}
</script>

<style lang="scss" scoped>
.card-header {
  font-size: 1rem;
  color: $primary;
}
</style>
